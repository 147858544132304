import React, { Component } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import './SideMenu.css'

class SideMenu extends Component{
    constructor(props){
        super(props)
        this.state = {collapsed:true};
    }

    toggleNavbar = () => this.setState({collapsed:!this.state.collapsed});

    render = () => {
        return(           
            <div id="menu">
                <Navbar color="faded" light>
                    <NavbarBrand href="/" className="mr-auto">La Croisade de la Prière</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav navbar>
                            <NavItem>
                                <NavLink onClick={()=>{this.props.onMenuChange("Stats")}}>Tableau de bord des requêtes ...</NavLink>
                            </NavItem>
                            
                            <NavItem>
                                <NavLink onClick={()=>{this.props.onMenuChange("Home")}}>Console de retranscription ...</NavLink>
                            </NavItem>
                            
                            <NavItem>
                                <NavLink onClick={()=>{this.props.onMenuChange("BookletsPage")}}>Livrets ...</NavLink>
                            </NavItem>
                            
                            <NavItem>
                                <NavLink onClick={()=>{this.props.onMenuChange("Suivis")}}>Suivi des requêtes ...</NavLink>
                            </NavItem>
                            
                            <NavItem>
                                <NavLink onClick={()=>{this.props.onMenuChange("ThanksPage")}}>Remerciements ...</NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink onClick={()=>{this.props.onMenuChange("Archives")}}>Requêtes archivées ...</NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink onClick={()=>{this.props.onDisconnect()}}>Deconnexion</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
} 

export default SideMenu;