import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import SaveAlt from '@material-ui/icons/SaveAlt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import "./ModalBookletContent.css"

class ModalBookletContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal:false,
      booklet_data:[],
      published:false
    };
  }

  componentDidMount = () => {
    this.service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4002/booklet-content'
                            :`https://envois.jesusestlareponsepourtous.org/booklet-content`;
  }

  toggle = () => {
    this.setState({modal:!this.state.modal}, () => {if(this.state.modal){this.fetchBookletContentFromDatabase()};  console.log("modal:", this.state.modal, "idenvois:", this.props.idenvois)})
  }

  RichTextRenderer = () => {
    const renderedWordcontent = Object.values(this.state.booklet_data).map(
        content => {
         return(
              <div>
                  <hr/>
                  <h5>Rubrique:&nbsp;{content.volet}</h5>
                  <div dangerouslySetInnerHTML={{__html : content.paragraphe.replace(/<crlf>/g, "</br>")}} /> 
              </div>
          )  
        }
    )

    return(<div>{renderedWordcontent}</div>);
  }

  fetchBookletContentFromDatabase = async event => {
    try{
        //call envois-service, to retrieve booklet text content
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        const serviceResult = await axios.post(this.service_db_url,{idenvois:this.props.idenvois});

        //Get service aknowledge before resetting state values.
        if (serviceResult.data.state === true){
            console.log("requête envoyée : result.data.message => ", serviceResult.data.message);
            
            //Display back full requetes paragraph in text area
            this.setState(
                {booklet_data:serviceResult.data.items, published:true}, 
                ()=>{console.log("state after publish", this.state);});
        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
      alert("Vérifiez votre connexion Wifi : " + err);
    }
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.toggle}>&times;</button>;
    return (
      <div>
        {/* Set here, "Copy/paste" booklet content icon 
        <SaveAlt onClick={this.toggle}/>*/}
        <MenuBookTwoToneIcon onClick={this.toggle}/>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader>
            <h3>{this.props.titre_envoi}</h3>
            Contenu du livret
          </ModalHeader>
          <ModalBody>
                {/* Contenu du livret */}
                <q>Faites un copier pour coller le contenu du livret dans Word</q>
                <div className="col-sm-12 booklet-container">
                    {this.RichTextRenderer()}
                </div> 
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle} hidden={!this.state.published}>Fermer</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalBookletContent;