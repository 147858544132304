import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import {Button} from 'reactstrap';
import Retranscriptions from './Components/Retranscriptions/Retranscriptions';
import SideMenu from './Components/SideMenu/SideMenu';
import StatsTable from './Components/StatsTable/StatsTable';
import BookletsPage from './Components/BookletsPage/BookletsPage'
import ThanksPage from './Components/ThanksPage/ThanksPage';
import Suivis from './Components/Suivis/Suivis'
import Archives from './Components/Archives/Archives'
import AdminAuthent from './Components/AdminAuthent/AdminAuthent'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const menu = {Home:0, Stats:1, BookletsPage:2, Suivis:3, Archives:4, Disconnect:5, ThanksPage:6};
const _seconds = 1;
const _minutes = 60 * _seconds;
const _cookie_name = "user_was_authentified"

class App extends Component { 
  constructor(props){
    super(props);
    this.debug = false;

    if (this.debug){
      this.setCookies("1");
      this.state = {menuState:menu.Home, authentified:1}/*Debugged page*/
    }
    else{
      this.state = {menuState:menu.Stats, authentified:0}/*Default page*/
    }
  }

  componentDidMount = () => {
    //Enforce cookies reading as page has been refreshed
    this.getCookies();
  }

  setCookies = (cookie_value) => {
    cookies.set(_cookie_name, cookie_value, { path: '/', maxAge : 30 * _minutes});
  } 

  removeCookies = () => {
    cookies.set(_cookie_name);
  } 

  getCookies = () => {
    let cookie_value = cookies.get(_cookie_name);
    const cookie_as_num = (cookie_value !== 'undefined' && cookie_value != null)?parseInt(cookie_value,10):0;
    //this.setState({authentified : cookie_as_num}, ()=>{console.log("State after cookie set :", this.state)});
    this.setState({authentified : cookie_as_num});
    return cookie_as_num;
  }

  onMenuChange = selectedMenu => {
    this.setState({menuState:menu[selectedMenu]});
  }

  onDisconnect = () =>{
    this.setState({authentified:0})
    //Remove cookie
    this.removeCookies();
  }

  onConnect = () => {
    //Set cookie
    this.setCookies("1");
    //Set State
    this.setState({authentified:1});
  }
  
  render = () =>{
    return (
      <div className="App">
        {this.state.authentified == 2 && 
        <div>
        <h1 style= {{color:"blue"}}>Déploiement en cours...</h1>
        <h3>L'application sera à nouveau disponible d'ici midi aujourd'hui 10 septembre 2021</h3></div>}

        {this.state.authentified == 0 && 
        <AdminAuthent onOperatorGranted={()=>{this.onConnect()}}/>}

        {this.state.authentified == 1 &&
        <div>
          <SideMenu onMenuChange = {this.onMenuChange} onDisconnect={this.onDisconnect}/>
          <div>
            {(this.state.menuState == menu.Home) && 
              <div>
                {/*<h1 className="App-header-disabled">Jésus est la réponse pour tous</h1>*/}
                <h4>Console de retranscription des requêtes</h4>
                <Retranscriptions mode="remote"/>
              </div>
            }

            {(this.state.menuState == menu.Stats) && 
              <div>
                <h4>Tableau de bord des requêtes</h4>
                <StatsTable/>
              </div>
            }
            
            {(this.state.menuState == menu.BookletsPage) && 
              <div>
                <h4>Livrets</h4>
                <BookletsPage/>
              </div>
            }
            
            {(this.state.menuState == menu.Suivis) && 
              <div>
                <h4>Campagnes de Suivis de requêtes</h4>
                <Suivis/>
              </div>
            }

            {(this.state.menuState == menu.ThanksPage) && 
              <div>
                <h4>Remerciements</h4>
                <ThanksPage/>
              </div>
            }
            
            {(this.state.menuState == menu.Archives) && 
              <div>
                <h4>Requêtes archivées</h4>
                <Archives/>
              </div>
            }
            {this.debug
            && <div>
              <h2>Cookies console</h2>
              <Button onClick={()=>{this.setCookies("Maxime")}}>Maxime</Button>
              <Button onClick={()=>{this.setCookies("Tulipe")}}>Tulipe</Button>
              <Button onClick={this.getCookies}>Read Cookie</Button>
              <Button onClick={this.removeCookies}>Delete Cookie</Button>
            </div>
            }
          </div>
        </div>}
      </div>
    );
  }
}

export default App;
