/*
BookletsCombo Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";

class BookletsCombo extends Component{
    constructor(props){
        super(props);
        this.state = {booklets:[], selected_booklet_object:null, open:false}
    }

    componentDidMount = () => {
        this.service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?`http://localhost:4002/${(this.props.publishedOnly)?"published-booklets-list":"lst-blk"}`
                            :`https://envois.jesusestlareponsepourtous.org/${(this.props.publishedOnly)?"published-booklets-list":"lst-blk"}`;
        this.fetchBooklets();
    }

    fetchBooklets = async () => {
        const fetchresult = await axios.get(this.service_db_url);
        if (this.props.defaultSelection){
            fetchresult.data.items = [this.props.defaultSelection, ...fetchresult.data.items]
        }

        this.setState({booklets : fetchresult.data.items}, () => {this.setState({selected_booklet_object:this.findBookletObjectWithId(this.props.rowData.idenvois)});});
    };

    findBookletObjectWithId = (idenvois_searched) => {//Look for booklet object given a idenvois
        let ii = 0;
        while (ii < this.state.booklets.length){
            if (this.state.booklets[ii].idenvois == idenvois_searched) return this.state.booklets[ii];
            ii++;
        }
        return null;
    }

    bookletObjectRendered = (bookletObject) => {
        if (!bookletObject)
            return(<span>Choisir un livret ...</span>)
        else
            return(
                <span>
                    <span>&nbsp;{bookletObject.titre_envoi}</span>  
                </span>
            );
    }

    bookletsDropDownRenderer = () => {
        const renderedDropDown = Object.values(this.state.booklets).map(
            bookletObjectItem => {
                return (
                    <DropdownItem 
                        key={bookletObjectItem.idenvois} 
                        onClick = {() => 
                                        {
                                            this.setState({selected_booklet_object:bookletObjectItem}); 
                                            if (this.props.OnClickBooklet_callback) 
                                                this.props.OnClickBooklet_callback(bookletObjectItem); 
                                            else alert("Callback is not yet defined");}}>
                        {this.bookletObjectRendered(bookletObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.bookletObjectRendered(this.state.selected_booklet_object)}
                    </DropdownToggle>

                    <DropdownMenu>
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>{this.bookletsDropDownRenderer()}</div>
        )
    }
}

export default BookletsCombo;