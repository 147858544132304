/*
Etats2 Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";

class Etats2 extends Component{
    constructor(props){
        super(props);
        this.state = {etats:[], selected_etat_object:null, open:false}
    }

    componentDidMount = () => {
        this.service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4004/ett-lst'
                            :`https://etats.jesusestlareponsepourtous.org/ett-lst`;
        this.fetchEtats();
    }

    fetchEtats = async () => {
        const fetchresult = await axios.get(this.service_db_url);
        if (this.props.defaultSelection){
            fetchresult.data.items = [this.props.defaultSelection, ...fetchresult.data.items]
        }

        this.setState({etats : fetchresult.data.items}, () => {this.setState({selected_etat_object:this.findEtatObjectWithId(this.props.rowData.idetats)});});
    };

    findEtatObjectWithId = (idetats_searched) => {//Look for etat object given a idetats
        let ii = 0;
        while (ii < this.state.etats.length){
            if (this.state.etats[ii].idetats == idetats_searched) return this.state.etats[ii];
            ii++;
        }
        return null;
    }

    etatObjectRendered = (etatObject) => {
        if (!etatObject)
            return(<span>Choisir un etat ...</span>)
        else
            return(
                <span>
                    <span>&nbsp;{etatObject.titre_etat}</span>  
                </span>
            );
    }

    etatsDropDownRenderer = () => {
        const renderedDropDown = Object.values(this.state.etats).map(
            etatObjectItem => {
                return (
                    <DropdownItem 
                        key={etatObjectItem.idetats} 
                        onClick = {() => 
                                        {
                                            this.setState({selected_etat_object:etatObjectItem}); 
                                            if (this.props.OnClickEtat_callback) 
                                                this.props.OnClickEtat_callback(etatObjectItem); 
                                            else alert("Callback is not yet defined");}}>
                        {this.etatObjectRendered(etatObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.etatObjectRendered(this.state.selected_etat_object)}
                    </DropdownToggle>

                    <DropdownMenu>
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>{this.etatsDropDownRenderer()}</div>
        )
    }
}

export default Etats2;