/*
Rubriques2 Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";

class Rubriques2 extends Component{
    constructor(props){
        super(props);
        this.state = {rubriques:[], selected_rubrique_object:null, open:false}
    }

    componentDidMount = () => {
        this.service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4003/rbq-lst'
                            :"https://rubriques.jesusestlareponsepourtous.org/rbq-lst";

        this.fetchRubriques();
    }

    fetchRubriques = async () => {
        const fetchresult = await axios.get(this.service_db_url);
        if (this.props.defaultSelection){
            //Add new default item {"idrubriques":"3","titre_rubrique":"Spiritualité","tri":"1"}
            fetchresult.data.items = [this.props.defaultSelection, ...fetchresult.data.items]
        }

        this.setState({rubriques : fetchresult.data.items}, () => {this.setState({selected_rubrique_object:this.findRubriqueObjectWithId(this.props.rowData.idrubriques)});});
    };

    findRubriqueObjectWithId = (idrubriques_searched) => {//Look for rubrique object given a idrubriques
        let ii = 0;
        while (ii < this.state.rubriques.length){
            if (this.state.rubriques[ii].idrubriques == idrubriques_searched) return this.state.rubriques[ii];
            ii++;
        }
        return null;
    }

    rubriqueObjectRendered = (rubriqueObject) => {
        if (!rubriqueObject)
            return(<span>Choisir une rubrique ...</span>)
        else
            return(
                <span>
                    <span>&nbsp;{rubriqueObject.titre_rubrique}</span>  
                </span>
            );
    }

    rubriquesDropDownRenderer = () => {
        const renderedDropDown = Object.values(this.state.rubriques).map(
            rubriqueObjectItem => {
                return (
                    <DropdownItem key={rubriqueObjectItem.idrubriques} onClick = {() => {this.setState({selected_rubrique_object:rubriqueObjectItem}); if (this.props.OnClickRubrique_callback) this.props.OnClickRubrique_callback(rubriqueObjectItem); else alert("Callback is not yet defined");}}>
                        {this.rubriqueObjectRendered(rubriqueObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.rubriqueObjectRendered(this.state.selected_rubrique_object)}
                    </DropdownToggle>

                    <DropdownMenu>
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>{this.rubriquesDropDownRenderer()}</div>
        )
    }
}

export default Rubriques2;