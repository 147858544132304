/*
ModalRevisions component
*/

import React, {Component} from 'react';
import axios from 'axios';
import Rubriques2 from '../Rubriques2/Rubriques2';
import Etats from '../Etats/Etats';
//import Rubriques from '@bit/grismauve.neuronal-bible.subject-list'; 
import {FormErrors} from '../FormErrors/FormErrors';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalRevisions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      did_not_change : true,
      sujet_requete : this.props.rowData.sujet_requete,
      sujet_revise : this.props.rowData.sujet_revise,
      idrubriques : this.props.rowData.idrubriques,
      idetats : this.props.rowData.idetats,
      formErrors: {sujet_revise:''},
      chaine: this.props.rowData.chaine_de_priere
    };
    this.toggle = this.toggle.bind(this);
    this.chainedepriereCheckBoxRef = React.createRef();
  }

  componentDidMount = () =>{
    this.handle_sujet_revise = this.handle_sujet_revise.bind(this);
    this.onChangeCheckboxChaineDePriere = this.onChangeCheckboxChaineDePriere.bind(this);
    this.onClickCheckboxChaineDePriere = this.onClickCheckboxChaineDePriere.bind(this);

    this.requetes_service_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4001/req-upd-chaine'
                            :`https://requetes.jesusestlareponsepourtous.org/req-upd-chaine`; 
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      rowData: this.props.rowData,
      sujet_requete : this.props.rowData.sujet_requete,
      sujet_revise : this.props.rowData.sujet_revise,
      idrubriques : this.props.rowData.idrubriques,
      idrequetes : this.props.rowData.idrequetes,
      idetats : this.props.rowData.idetats,
      chaine : this.props.rowData.chaine_de_priere
    }), ()=>{console.log("state after toggle =>", this.state," props => ", this.props)});
  }

  onFormSubmitHandler = async event => {
    event.preventDefault();
    
    //call micro-service
    try{
        //call requetes-service, to update content
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        const serviceResult = await axios.put(this.requetes_service_url,this.state);

        //Get service aknowledge before resetting state values.
        if (serviceResult.data.state === true){
          console.log("onFormSubmitHandler::serviceResult returns from requete-service:", serviceResult.data.message);
            
        //Refresh table
        if (this.props.refreshHandler)
          this.props.refreshHandler();
        
        //Close modal form
        this.toggle();

        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
      alert("Vérifiez votre connexion Wifi : " + err);
    }
  }  

  handle_sujet_revise = (e) => {
    e.preventDefault();
    //Update sujet_revise value from form field
    this.setState({did_not_change:false, sujet_revise:e.target.value}, () => {this.validateField(e.target.name, e.target.value);});
  }

  onChangeCheckboxChaineDePriere = (e) => {
    this.setState({chaine:e.target.checked?1:0});
  }

  onClickCheckboxChaineDePriere = (e) => {
    this.setState({chaine:!this.chainedepriereCheckBoxRef.current.checked?1:0});
  }

  _OnClickRubrique_callback = (selected_rubrique) => {
    this.setState({idrubriques:selected_rubrique.idrubriques});
  }

  _OnClickEtat_callback = (selected_etat) => {
    this.setState({idetats:selected_etat.idetats});
  }

  errorClass = (error) => {
    return(error.length === 0 ? '' : 'has-error');
  }

  validateForm = () => {
      this.setState({formValid: this.state.emailValid && this.state.passwordValid});
  }

  validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let sujetReviseValid = this.state.sujetReviseValid;
    
      switch(fieldName) {
        case 'sujet_revise':
          sujetReviseValid = value.length <= 300;
          fieldValidationErrors.sujet = sujetReviseValid ? '': ' est trop long';
          break;

        default:
          break;
      }

      this.setState({formErrors: fieldValidationErrors,
                      sujetReviseValid: sujetReviseValid
                    }, this.validateForm);
  }

  formDisabled = () => {
    return (
        !(this.state.sujet_revise.length > 0
        && (this.state.idrubriques != null)
        && this.state.formErrors.sujet_revise.length == 0
        ));
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <div>
        <span
            style={{ height: 25,
                width: 25,
                color:'blue',
                cursor:'pointer'
            }}
            onClick={this.toggle}>
              {this.props.rowData.sujet_revise}
        </span>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader>Révision de la requête</ModalHeader>
          <ModalBody>
                <div className="panel panel-default">
                    <FormErrors formErrors={this.state.formErrors}/>
                </div>
                
                <form id="requete-form" onSubmit={this.onFormSubmitHandler}> 
                <div className={`form-group
                 ${this.errorClass(this.state.formErrors.sujet_revise)}`}/>                   
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Rubrique:</label>
                        <div className="col-sm-10">
                            <Rubriques2 OnClickRubrique_callback = {this._OnClickRubrique_callback} rowData = {this.props.rowData}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Etat:</label>
                        <div className="col-sm-10">
                            <Etats OnClickEtat_callback = {this._OnClickEtat_callback} rowData = {this.props.rowData}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label"> </label>
                        <div className="col-sm-8">
                            <input className="col-sm-2" ref={this.chainedepriereCheckBoxRef} type="checkbox" checked={this.state.chaine==1?true:false} onChange={(e)=>{this.onChangeCheckboxChaineDePriere(e);}}/><label onClick={(e)=>{this.onClickCheckboxChaineDePriere(e);}}>Publication Chaîne de prière.</label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Original:</label>
                        <div className="col-sm-10">
                            <textarea name="sujet" className="form-control" value={this.state.sujet_requete} type="text" readonly="true"/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Révisé:</label>
                        <div className="col-sm-10">
                            <textarea name="sujet_revise" className="form-control" value={this.state.sujet_revise} onChange={(e)=>{this.handle_sujet_revise(e);}} type="text" placeholder="Saissez le sujet de la prière révisé"/>
                        </div>
                    </div>
                </form>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" form="requete-form" disabled={this.formDisabled()}>Enregistrer</Button>
            <Button color="secondary" onClick={this.toggle}>Annuler</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalRevisions;