import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

class ModalConfirmArchivageRequete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      archived:false
    };
  }
  
  componentDidMount = () => {
    this.service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4001/req-push-to-archive'
                            :`https://requetes.jesusestlareponsepourtous.org/req-push-to-archive`;
  }

  onArchivingClickHandler = async event => {
    event.preventDefault();

    //Build request id's list 
    console.log("onArchivingClickHandler => data", this.props.data)
    const idrequetes_list = Object.values(this.props.data).map(
      requete => {
          return (
              `${requete.idrequetes}`
          )
      }
    );

    try{
        //call requete-service
        const serviceResult = await axios.put(
          this.service_db_url,
            {idrequetes_list : idrequetes_list.join(",")});

        //Get service aknowledge before resetting state values.
        if (serviceResult.data.state === true){
            console.log("requête archivées : result.data.message => ", serviceResult.data.message);
            
            //Display back full requetes paragraph in text area
            this.setState({archived:true}, ()=>{console.log("state after archiving", this.state);});
        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
      alert("Vérifiez votre connexion Wifi : " + err);
    }
  }

  render = () => {
    {/*const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.toggle}>&times;</button>;*/}
    return (
      <div>
        <DeleteOutline className="CursorPointer" onClick={this.toggle}/>
        {/*<SaveAlt onClick={() => {alert("Toggle twice !"); this.props.toggle();}}/>
        <SaveAlt/>*/}
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>{/* External props.toggle in order to support material table global action button */}
          <ModalHeader>Archivage de requêtes</ModalHeader>
          
          <ModalBody>
                <q>
                  Cette opération vise à archiver la ou les requetes sélectionnées.<br/>Ces requêtes ne seront plus visibles mais pourrons néanmoins être visibles depuis le menu Archives.
                </q>

                {this.state.archived && <div><br/><strong>Requête(s) archivée(s)</strong></div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onArchivingClickHandler} hidden={this.state.archived}>Archiver</Button>
            <Button color="secondary" onClick={() => {this.props.toggle();}} >Fermer</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirmArchivageRequete;